








































































































































.text-field-percentage {
  input {
    text-align: right !important;
  }
}
